import React from "react"
import Layout from "../components/layout"
import TopHead from "../components/top-head"
import { graphql } from "gatsby"
import { Helmet } from 'react-helmet'
import Img from "gatsby-image"

const Team = (props) => (
    <>
    <Helmet title="Team | IRT" defer={false} />
    <Layout>
        <TopHead photo={props.data.imageOne.childImageSharp.fluid} title="Das IRT Team" para="Hier können Sie unsere Therapeuten und Ärzte sehen."/>
        <section className="m-20 mb-24 flex flex-row flex-wrap w-11/12 max-w-6xl">

<div className="bg-blue-500 w-full">
  <div className="mx-auto px-4 sm:px-6 lg:px-8">
    <div className="space-y-12">
      <ul className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-3 lg:gap-x-8 bg-blue-500">
        <li>
          <div className="space-y-4 bg-white rounded-lg p-2">
            <div className="">
              <Img className="" fluid={props.data.Jan.childImageSharp.fluid} className=""/>
            </div>
            <div className="space-y-2">
              <div className="text-lg leading-6 font-medium space-y-1">
                <h3>Jan Wolff</h3>
                <p className="text-blue-500">Gründer und betreuender Arzt</p>
                <p className="text-gray-400 text-sm">j.wolff@irt-mtt.de</p>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="space-y-4 bg-white rounded-lg p-2">
            <div className="">
              <Img className="" fluid={props.data.Matt.childImageSharp.fluid} className=""/>
            </div>
            <div className="space-y-2">
              <div className="text-lg leading-6 font-medium space-y-1">
                <h3>Matthias Sauer</h3>
                <p className="text-blue-500">Gründer und Therapeut</p>
                <p className="text-gray-400 text-sm">m.sauer@irt-mtt.de</p>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="space-y-4 bg-white rounded-lg p-2">
            <div className="">
              <Img className="" fluid={props.data.Murat.childImageSharp.fluid} className=""/>
            </div>
            <div className="space-y-2">
              <div className="text-lg leading-6 font-medium space-y-1">
                <h3>Murat Özbek</h3>
                <p className="text-blue-500">Therapeut</p>
                <p className="text-gray-400 text-sm">m.oezbek@irt-mtt.de</p>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="space-y-4 bg-white rounded-lg p-2">
            <div className="">
              <Img className="" fluid={props.data.Christ.childImageSharp.fluid} className=""/>
            </div>
            <div className="space-y-2">
              <div className="text-lg leading-6 font-medium space-y-1">
                <h3>Christian Schmidt</h3>
                <p className="text-blue-500">Therapeut</p>
                <p className="text-gray-400 text-sm">c.schmidt@irt-mtt.de</p>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="space-y-4 bg-white rounded-lg p-2">
            <div className="opacity-0">
              <Img className="" fluid={props.data.Christ.childImageSharp.fluid} className=""/>
            </div>
            <div className="space-y-2">
              <div className="text-lg leading-6 font-medium space-y-1">
                <h3>Susen Berger</h3>
                <p className="text-blue-500">Therapeutin</p>
                <p className="text-gray-400 text-sm">s.berger@irt-mtt.de</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</div>

        </section>
    </Layout>
    </>
)

export default Team

export const pageQuery = graphql`
  query {
    imageOne: file(relativePath: { eq: "photo1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Jan: file(relativePath: { eq: "Jan5.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Matt: file(relativePath: { eq: "Matt1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Murat: file(relativePath: { eq: "Murat1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Christ: file(relativePath: { eq: "Christ2.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    Muc: file(relativePath: { eq: "muc.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;